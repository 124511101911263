var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8", "offset-md": "2" } },
            [
              _c(
                "v-list",
                { attrs: { nav: "", color: "transparent" } },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { disabled: "", color: "primary" },
                      model: {
                        value: _vm.invitationType,
                        callback: function ($$v) {
                          _vm.invitationType = $$v
                        },
                        expression: "invitationType",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center", align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c("v-list-item-content", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "overline mt-0 mb-1 d-flex justify-center",
                                      },
                                      [_vm._v("OPTION 1")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex justify-center" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "subtitle-2 secondary--text text-uppercase",
                                          },
                                          [_vm._v("user invitation")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c("v-list-item-content", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "overline mt-0 mb-1 d-flex justify-center",
                                      },
                                      [_vm._v("OPTION 2")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex justify-center" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "subtitle-2 secondary--text text-uppercase",
                                          },
                                          [_vm._v("administrator invitation")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.invitationType !== undefined
        ? _c("div", [
            _vm.invitationType ===
              _vm.mainInvitationOptions.INSTANCE_USER_INVITATION &&
            _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mt-10 d-flex justify-space-around align-center",
                  },
                  [
                    _c(
                      "v-radio-group",
                      {
                        attrs: { row: "" },
                        model: {
                          value: _vm.instanceInvitationType,
                          callback: function ($$v) {
                            _vm.instanceInvitationType = $$v
                          },
                          expression: "instanceInvitationType",
                        },
                      },
                      _vm._l(
                        _vm.availableInstanceOptions,
                        function (instanceInvitationOption, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "d-flex justify-space-between",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center flex-column",
                                },
                                [
                                  _c("v-radio", {
                                    staticClass: "mt-3 caption",
                                    attrs: {
                                      color: "primary",
                                      value: instanceInvitationOption.value,
                                      label:
                                        instanceInvitationOption.radioLabel,
                                    },
                                  }),
                                  instanceInvitationOption.radioSubHeader
                                    ? _c(
                                        "add-instance-dialog",
                                        {
                                          on: {
                                            inviteUsers: function ($event) {
                                              return _vm.updateDataForTargetInstance(
                                                {
                                                  instanceName:
                                                    $event.instanceName,
                                                }
                                              )
                                            },
                                            dialogIsOpen: function ($event) {
                                              return _vm.moveToInviteToExistingInstanceTab(
                                                $event.value
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-hover", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ hover }) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          class: [
                                                            hover
                                                              ? "secondary--text"
                                                              : "secondary--text",
                                                            "font-weight-bold",
                                                            "caption",
                                                          ],
                                                          style: hover
                                                            ? "cursor: pointer;"
                                                            : "",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                instanceInvitationOption.radioSubHeader
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _vm.displayedComponent === "TheInstanceInvite"
                  ? _c("TheInstanceInvite", {
                      tag: "component",
                      attrs: {
                        dataForInstanceToInviteTo:
                          _vm.dataForInstanceInvitation,
                      },
                    })
                  : _c(_vm.displayedComponent, { tag: "component" }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }